import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope } from "react-icons/bs";
import { MdPermPhoneMsg } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Juna& Glate
			</title>
			<meta name={"description"} content={"Відкрийте для себе неприборканий дух мотоциклів завдяки унікальній колекції мотоциклів та навчанню, яке не схоже на звичайне."} />
			<meta property={"og:title"} content={"Juna& Glate"} />
			<meta property={"og:description"} content={"Відкрийте для себе неприборканий дух мотоциклів завдяки унікальній колекції мотоциклів та навчанню, яке не схоже на звичайне."} />
			<meta property={"og:image"} content={"https://junaandglate.com/pics/contact.jpg"} />
			<link rel={"shortcut icon"} href={"https://junaandglate.com/pics/1036184.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://junaandglate.com/pics/1036184.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="linear-gradient(180deg,rgba(26, 45, 153, 0.9) 0%,rgba(26, 45, 153, 0.9) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://junaandglate.com/pics/contact.jpg) 50% 50% /auto repeat scroll padding-box" quarkly-title="Contacts-7" color="--light">
			<Box min-width="100px" min-height="100px" padding="0px 0px 30px 0px" text-align="center">
				<Text as="h1" font="--headline2" margin="10px 0 0 0">
					Зв'яжіться з нами{" "}
					<br />
					для отримання додаткової інформації
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Адреса
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						Вулиця Яворівська, 33, Зимна Вода, Львівська область, 81110
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Email
					</Text>
					<Link
						href="tel:support@junaandglate.com"
						color="#dae0e5"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 8px 0px"
					>
						support@junaandglate.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="md"
						icon={MdPermPhoneMsg}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Телефон
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						38097 874 1532
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});